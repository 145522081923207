import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/SEO'

import '../ui/apropos.css'


const post = {
  title: "Ouppss, cette page n'existe pas ou n'existe plus..",
  description: 'Si vous cherchez des bombes de bain, vous êtes au bonne endroit, mais pas à la bonne page',
  image: 'https://archimouss.ch/assets/images/logo/big.png'
}

const ErrorNotFound = () => {
  return (
    <>
      <Seo title={post.title} description={post.description} image={post.image} index={true} />
    
      <Layout displayBasket={true}>

          <div className='main'>
            <div className='main-content'>
                <div className='apropos-main-content'>
                      <div className='apropos-content'>
                        <h1>Ouppss, cette page n'existe pas ou n'existe plus..</h1>
                        <p>Que cherchiez vous ?</p>
                      
                        <div>
                          <p>De suberbe bombes de bain ?</p>
                          <div class="button-container">
                            <Link className="accent-button" alt="voir les produits bombes de bain" to="/bombe-bain">Voir les bombes de bain</Link>
                          
                          </div>
                          <br/>
                            <br/>
                            <p>Des réponses à vos questions ?</p>
                            <div class="button-container">
                            <Link className="accent-button" alt="voir le blog bombes de bain" to="/blog">Voir le blog</Link> 
                            </div>
                          </div>
                      </div>
                  </div>
              
            </div>
          </div>

      </Layout>
    </>
  )
}

export default ErrorNotFound